import React, { useEffect } from 'react';
import Header from './components/Header';
import Blog from './components/Blog';
export default function BlogDetails(props) {
  useEffect(() => {
    document.title = "Blogs - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);

  return (
    <div className='section-padding pt-200'>
      {/* <Header /> */ }
      <Blog />
      <div className='mt-5 pt-3' />
    </div>
  )
}
