import { get, post, del, put, postFd } from "../utils/axios"

const api = "blog-management/blogs";

export const getBlogCategoryDependencies = () => {
    return get(`${api}/dependencies`);
}

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getBlogs = (payload) => {
    return get("get-blogs", payload);
}

export const getBlog = (slug) => {
    return get(`blog/${slug}`);
}

export const createBlog = (payload) => {
    return post(api, payload);
}

export const updateBlog = (payload) => {
    return put(`${api}/${payload.id}`, payload);
}

export const deleteBlog = (id) => {
    return del(`${api}/${id}`);
}
export const uploadImage = (payload) => {
    return postFd(`${api}/image`, payload);
}

export const getCountries = () => {
    return get("get-countries")
}

export const createContact = (payload) => {
    return post("contact-request", payload);
}

export const getVideos = () => {
    return get("get-videos")
}