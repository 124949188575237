import React, { useEffect } from 'react';
import Blog from './components/Blog';
import GradientCTA from '../faqs/components/GradientCTA';
import Header from './components/Header';
export default function Blogs() {
  useEffect(() => {
    document.title = "Blogs - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);

  return (
    <div className='pt-200'>
      <Header />
      <div className='mt-5 pt-3' />
      <Blog />
      <GradientCTA />
    </div>
  )
}
