import React from 'react';

export default function Header() {
  return (
    <div>
      <div className="banner relative z-1">
        <img src="assets/images/banner/element_1.png" className="element_1" alt="Element" />
        <img src="assets/images/banner/element_line.png" className="element_line" alt="Element Line" />
        <div className="transform-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="text_box">
                  <h1 className="title">Want to study abroad?</h1>
                  <h5>Apply for Upcoming Intake
                  </h5>

                  <a href="/contact-us" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rounded mr-4 mb-4">Apply Now <i className="fal fa-chevron-right ml-2" /></a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image_box relative">
                  <iframe width={ 580 } height={ 455 } src="https://www.youtube.com/embed/oKu4GAeGjp8?autoplay=1&mute=1&showinfo=0" title=" YouTube video player" formborder={ 0 } allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
              </div>
            </div>
            <div>

            </div>

          </div>
        </div>
      </div>
    </div>

  );
}
