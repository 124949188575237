import React, { useEffect } from 'react';
import FAQ from './components/faq';
import GradientCTA from './components/GradientCTA';
import Header from './components/Header';
export default function FAQs() {
  useEffect(() => {
    document.title = "F.A.Q - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);
  return (
    <div className='pt-200'>
      <Header />
      <div className='mt-5 pt-3' />
      <FAQ />
      <GradientCTA />
    </div>
  );
}
