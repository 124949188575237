import React from 'react';

export default function LegacySection() {
  return (
    <section className="bg-thm-color-two-gradient z-1 video_quote">
      <img src="assets/images/elements/element_2.png" className="element_2" alt="Element" />
      <div className="container-fluid p-0">
        <div className="row no-gutters align-items-center">
          <div className="col-lg-6">
            <div className="video_warp relative z-1 h-100">
              <img src="assets/images/newimages/student.png" alt="img" className="image-fit" />
              <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="popup-youtube video_btn transform-center justify-content-center d-flex style_2">
                <i className="fas fa-play video_icon bg-thm-color-three pulse-animated"></i>
              </a>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="arrows slideRight">
              <div className="arrow"></div>
              <div className="arrow"></div>
              <div className="arrow"></div>
              <div className="arrow"></div>
              <div className="arrow"></div>
            </div>
            <img src="assets/images/elements/element_11.png" className="element_4 rotate_elem" alt="img" />
            <div className="quote_sec about relative z-1">
              <img src="assets/images/elements/element_17.png" className="element_5 rotate_elem" alt="img" />
              <div className="section-title left-align wow fadeInDown">
                <h3 className="title mb-0">Our legacy</h3>
              </div>
              <p>With over 10 years of experience, we have helped 4000+ students to<br /> secure admissions in 5+ countries and 55+ international universities with <br />99% success rate.</p>
              <div className="col-sm-12">
                <a href="/contact-us" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rectangle">Join Our Legacy Now <i className="fal fa-chevron-right ml-2"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}