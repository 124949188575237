export const data = [
  {
    title: "Study SKills",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/study_img.png",
    icon: "assets/images/couching/study.png"
  }
  ,
  {
    title: "Goal Setting",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/goals_img.png",
    icon: "assets/images/couching/goals.png"
  }
  ,
  {
    title: "Connecting With Resources",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/connecting_img.png",
    icon: "assets/images/couching/connecting.png"
  }
  ,
  {
    title: "Organisation",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/org_img.png",
    icon: "assets/images/couching/org.png"
  },
  {
    title: "Academic & Social Science",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/social1.png",
    icon: "assets/images/couching/social.png"
  },
  {
    title: "Assesment Strategies",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/strategy_img.png",
    icon: "assets/images/couching/strategy.png"
  },
  {
    title: "Time Management",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/time_img.png",
    icon: "assets/images/couching/time.png"
  },
  {
    title: "Motivation",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
    img: "assets/images/couching/motivation_img.png",
    icon: "assets/images/couching/study.png"
  }
]