import BaseHeader from "./BaseHeader";
import BaseFooter from "./BaseFooter";

const BaseLayout = (props) => {

    return (
        <>
            <BaseHeader />
            { props.children }
            <BaseFooter />
        </>
    )
}

export default BaseLayout;