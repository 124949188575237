import React from 'react';

export default function WhatWeKnow() {
  return (<div>
    {/* What We Know Start */ }
    <section className="what_we_know section pt-0">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="image_box relative z-1 text-center about_me mb-md-80">
              <img src="assets/images/about/somemask.png" alt="img" className="wow fadeInLeft" />
              <span className="bg-thm-color-three circle_element" />

              <img src="assets/images/elements/element_28.png" alt="element" className="element_1" />
              <img src="assets/images/elements/element_29.png" alt="element" className="element_2 rotate_elem" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="section-title left-align wow fadeInDown">

              <h3 className="title mb-0">Why Choose ICC?</h3>
            </div>
            <ul className="why-us-list style_2 style_3">
              <li className="wow fadeInUp">
                <i className="icon fal fa-check" />
                <div className="text">
                  <h6 className="mb-0">Counselling & Career Guidance</h6>
                </div>
                <img src="assets/images/bg_icc.jpg" alt="img" className="image-fit" />
              </li>
              <div className='text w-75 m-auto'>
                <p className='mb-5'>
                  Get personalized attention to choose courses and institutions that leverage your competency and suit your profiles.
                </p>
              </div>
              <li className="wow fadeInDown">
                <i className="icon fal fa-check" />
                <div className="text">
                  <h6 className="mb-0"> University Enrollment</h6>
                </div>
                <img src="assets/images/bg_icc.jpg" alt="img" className="image-fit" />
              </li>
              <div className='text w-75 mr-auto'>
                <p className='mb-5'>
                  We conduct orientation sessions to help students prepare about the upcoming events, boarding process, cultural dynamics, and enrollment plan.
                </p>
              </div>

            </ul>
          </div>
        </div>
      </div>
    </section>
    {/* What We Know End */ }

  </div>);
}
