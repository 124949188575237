import React from 'react';

export default function PricingDetails() {
  return (
    <section className="pricing_sec section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title wow fadeInDown">
              <p className="subtitle">
                <i className="fal fa-book" />
                Pricing Plan
              </p>
              <h3 className="title">Simple Pricing Plan For Business Coach</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">

            <div className="tab-content wow fadeInDown">
              <div className="tab-pane fade active show" id="monthly">
                <div className="row no-gutters justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box active">
                      <h4 className="plan_title">Basic Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$29.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box">
                      <h4 className="plan_title">Standard Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$49.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box">
                      <h4 className="plan_title">Premium Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$99.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="yearly">
                <div className="row no-gutters justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box ">
                      <h4 className="plan_title">Basic Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$29.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box active">
                      <h4 className="plan_title">Standard Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$49.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="plan_box">
                      <h4 className="plan_title">Premium Plan</h4>
                      <p className="plan_subtext">Quis autem veleum iure reprehenderit qui in ea voluptate velit esse</p>
                      <h2 className="plan_price">$99.92</h2>
                      <ul className="about_list style_2 mb-xl-30">
                        <li>
                          Business Consulting
                        </li>
                        <li>
                          Professional Trainings
                        </li>
                        <li>
                          Video Tutorials
                        </li>
                        <li>
                          Marketing Strategy
                        </li>
                      </ul>
                      <a href="contact-us" className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">Select Plan <i className="fal fa-chevron-right ml-2" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
