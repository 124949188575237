import React from 'react';

export default function Testimonials() {
  return (
    <div>
      <section className="section-padding relative z-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title left-align">
                <h3 className="title mb-0">Our Success Stories</h3>
              </div>
            </div>
          </div>
          <div className="testimonial_slider row arrows_none">
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/rabia.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Rabia Saeed</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">BS biomedical Science</p>
                  </div>
                </div>
                <p className="comment">
                  University of Westminster- BS biomedical Science, Class of 2022
                </p>
              </div>
            </div>
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/saman.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Saman Bashir</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">BSC</p>
                  </div>
                </div>
                <p className="comment">
                  Glasgow Caledonian University
                </p>
              </div>
            </div>
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/misha.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Misha Ahsan</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">MBA</p>
                  </div>
                </div>
                <p className="comment">
                  Bayes Business School - City University of London
                </p>
              </div>
            </div>
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/khuzama.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Khuzama Sadaqat</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">BSC</p>
                  </div>
                </div>
                <p className="comment">
                  Curtin University Australia.
                </p>
              </div>
            </div>
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/sidra.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Sidra Rai</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">MSc</p>
                  </div>
                </div>
                <p className="comment">
                  Management and International Business, Nottingham Trent University
                </p>
              </div>
            </div>
            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/umair.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Umair Saif</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">Law</p>
                  </div>
                </div>
                <p className="comment">
                  BPP University of London - Bar At Law
                </p>
              </div>
            </div>

            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/adil.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Adil Ali Nadir</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">Law</p>
                  </div>
                </div>
                <p className="comment">
                  BPP University of London Bar At Law
                </p>
              </div>
            </div>

            <div className="slide-item col-12">
              <div className="testimonial_item height-245" >
                <div className="author">
                  <div className="image bg-thm-color-two">
                    <img src="assets/images/users/shahnawaz.jpg" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-0">Shahnawaz Memon</h6>
                    <p className="mb-0 font-weight-bold thm-color-two">Law</p>
                  </div>
                </div>
                <p className="comment">
                  LL.M in BPT City Law School, City, University of London
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="circle_wave" />
      </section>
    </div>

  );
}
