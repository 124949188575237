import React from 'react';

export default function About() {
  return (
    <div>
      <section className="section about_inner">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="image_box shadow_1 mb-md-80">
                <img src="assets/images/about/about.jpg" alt="img" className="image-fit" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title left-align">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  About Us
                </p>
                <h3 className="title">We Care About Your Life For Better Future</h3>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae inventore</p>
              </div>
              <ul className="about_list row">
                <li className="col-md-6">
                  <div className="icon">
                    <img src="assets/images/icons/icon_5.png" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Master Coach</h6>
                    <p className="mb-0">Quis autem reprehenderit quein voluptate velit esseua</p>
                  </div>
                </li>
                <li className="col-md-6">
                  <div className="icon">
                    <img src="assets/images/icons/icon_5.png" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Professional Advisors</h6>
                    <p className="mb-0">Quis autem reprehenderit quein voluptate velit esseua</p>
                  </div>
                </li>
                <li className="col-md-6">
                  <div className="icon">
                    <img src="assets/images/icons/icon_5.png" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Coach Certification</h6>
                    <p className="mb-0">Quis autem reprehenderit quein voluptate velit esseua</p>
                  </div>
                </li>
                <li className="col-md-6">
                  <div className="icon">
                    <img src="assets/images/icons/icon_5.png" alt="img" className="image-fit" />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Coaching Scholars</h6>
                    <p className="mb-0">Quis autem reprehenderit quein voluptate velit esseua</p>
                  </div>
                </li>
              </ul>
              <a href="#" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle">
                Learn More
                <i className="fal fa-chevron-right ml-2" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}
