import React from 'react';

export default function Professionals() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <p className="subtitle">
                <i className="fal fa-book" />
                Our Professionals
              </p>
              <h3 className="title">Meet Our Professinoal Team Members</h3>
            </div>
          </div>
        </div>
        <div className="row">
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/1.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Wallace D. Grace</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/2.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Evan A. Ethridge</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/3.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Marcus L. Duncan</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/4.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Paul B. Gilliland</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/5.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Donald T.Lewis</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/6.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Allan J. Sumlin</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/7.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Gracie T. Evans</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/8.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">David S. Hudson</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/9.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Michael Randall</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
          {/* Block Start */ }
          <div className="col-lg col-md-6">
            <div className="team_block style_2">
              <div className="team_img">
                <img src="assets/images/team/10.jpg" alt="img" className="image-fit" />
                <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                  <i className="fal fa-plus" />
                </a>
              </div>
              <h6 className="mb-1"><a href="#">Joseph Anderson</a></h6>
              <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
            </div>
          </div>
          {/* Block End */ }
        </div>
      </div>
    </section>


  );
}
