import React from 'react';

export default function GradientCTA() {
  return (
    <div>
      <div className="cta_box">
        <div className="container relative z-1">
          <div className="bg-thm-color-two cta_box_inner z-1 mt-negative">
            <img src="assets/images/elements/shape.png" alt="shape" className="shape_1 zoom-fade" />
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <div className="text_block">
                  <div className="section-title left-align white">

                    <h3 className="title">Ready to Consult your Counselor Today?</h3>
                    <a href="/contact-us" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rounded">Book your consultation now <i className="fal fa-chevron-right ml-2" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="image_box relative z-1">
                  <img src="assets/images/elements/square_shape.png" alt="shape" className="shape_2 rotate_elem" />
                  <img src="/assets/images/cta_image.png" alt="img" className="image-fit" />
                  <div className="arrows slideRight">
                    <div className="arrow" />
                    <div className="arrow" />
                    <div className="arrow" />
                    <div className="arrow" />
                    <div className="arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="arrows to_up slideTop">
            <div className="arrow" />
            <div className="arrow" />
            <div className="arrow" />
            <div className="arrow" />
            <div className="arrow" />
          </div>
        </div>
      </div>
    </div>

  );
}
