import React, { useEffect } from 'react';
import Header from './components/Header';
import Contact from './components/Contact';
export default function ContactUs() {
  useEffect(() => {
    document.title = "Contact Us - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);
  return (
    <div className='pt-200 pb-100' >
      <Header />
      <Contact />
    </div>
  );
}
