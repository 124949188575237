import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { post } from '../../../utils/axios';
import { getBlog } from '../../requests';

export default function Blog() {

    const [blog, setBlog] = useState()
    const { title } = useParams()
    console.log(title)

    useEffect(() => {
        getBlog(title).then(res => {
            console.log(res.data)
            setBlog(res.data)
        }).catch(console.error)

    }, [])

    if (!blog) return null

    return <div className="section-padding">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-8 mb-md-80">
                    {/* post details */ }
                    <article className="post post_list style_2 post_details mb-0 wow fadeInDown">
                        <div className="post_img">
                            <img src={ blog.image } alt="img" className="image-fit" />
                        </div>
                        <div className="post_caption">
                            <h4 className="post_title">
                                { blog.title }
                            </h4>
                            <div dangerouslySetInnerHTML={ { __html: blog.body } } />
                        </div>
                    </article>

                    {/* post details */ }
                </div>
                <div className="col-lg-4">
                    <div className="sidebar style_2">
                        <div className="sidebar_widget wow fadeInUp">

                        </div>

                        <div className="sidebar_widget recent_widgets wow fadeInUp">
                            <h5 className="widget_title">Latest Blogs</h5>
                            <ul>
                                {
                                    blog.latestPosts.map(post => (
                                        <li key={ post.id.toString() }>
                                            <div className="image">
                                                <img src={ post.image } alt="img" className="image-fit" />
                                            </div>
                                            <div className="text">
                                                <h6 className="mb-0">
                                                    <a href={ `/blogs/${post.slug}` }>{ post.title }</a>
                                                </h6>
                                                <p className="mb-0"><strong><i className="fal fa-calendar-alt" /> </strong>{ post.created_at.toString() }</p>
                                            </div>
                                        </li>
                                    ))
                                }



                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

}
