import React, { useEffect } from 'react';
import CouchingComponent from './components/CouchingComponent';
import { data } from './components/data';
import Header from './components/Header';
export default function AcademicCouching() {
  useEffect(() => {
    document.title = "Academic Coaching,International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);
  return <div className='pt-200'>
    <Header />
    <div className='pt-100' />
    <CouchingComponent
      title={ data[0].title }
      subtitle={ data[0].subtitle }
      img={ data[0].img }
      icon={ data[0].icon }
    />

    <CouchingComponent
      title={ data[1].title }
      subtitle={ data[1].subtitle }
      img={ data[1].img }
      icon={ data[1].icon }
      flag
    />
    <CouchingComponent
      title={ data[2].title }
      subtitle={ data[2].subtitle }
      img={ data[2].img }
      icon={ data[2].icon }
    />
    <CouchingComponent
      title={ data[3].title }
      subtitle={ data[3].subtitle }
      img={ data[3].img }
      icon={ data[3].icon }
      flag
    />
    <CouchingComponent
      title={ data[4].title }
      subtitle={ data[4].subtitle }
      img={ data[4].img }
      icon={ data[4].icon }
    />
    <CouchingComponent
      title={ data[5].title }
      subtitle={ data[5].subtitle }
      img={ data[5].img }
      icon={ data[5].icon }
      flag
    />
    <CouchingComponent
      title={ data[6].title }
      subtitle={ data[6].subtitle }
      img={ data[6].img }
      icon={ data[6].icon }
    />
    <CouchingComponent
      title={ data[7].title }
      subtitle={ data[7].subtitle }
      img={ data[7].img }
      icon={ data[7].icon }
      flag
      arrow={ false }
    />
  </div>;
}
