import React, { useState, useEffect } from 'react';
import { getCountries, createContact } from '../modules/requests';
const initialState = {
  name: "",
  phone: "",
  email: "",
  country_id: "",
  qualification: ""
}
const validationErrors = {
  name: "",
  email: "",
  phone: "",
}

export default function EmployeeModal() {
  const [state, setState] = useState(initialState)
  const [countries, setCountries] = useState([])

  const [success, setSuccess] = useState({
    isSuccess: false,
  })
  const [errors, setErrors] = useState(validationErrors)
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCountries();
      // console.log(response)
      setCountries(response.data);
    }
    fetchData().catch(console.error);;
  }, [])
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    createContact({ object: state }).then(res => {
      if (res.code === 200) {
        setSuccess({
          ...success,
          isSuccess: true,
          data: res.data
        })
        setErrors(validationErrors)
      } else {
        setErrors(res.data)
      }
    }).catch(console.err)
    setState(initialState)
  }
  if (!countries) return ''
  return (
    <div>
      <div className="modal fade" id="exampleModal" tabIndex={ -1 } role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Enroll Now</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              { success.isSuccess ? <div class="alert alert-success fade in show" role="alert">
                { success.data }
              </div> : null }
              <form className="mf_form_validate ajax_submit" onSubmit={ handleSubmit } action="sendmail.php" method="post" encType="multipart/form-data">
                <div className="row">
                  <div className="col-lg-12">

                    <div className="form-group form_style mb-0">
                      <label>Full Name</label>
                      <input required type="text" name="name" onChange={ handleChange } value={ state.name } className="form-control " autoComplete="off" placeholder="Full Name" />
                      {/* <div className="valid-feedback text-right">
                    Looks good!
                  </div> */}
                    </div>
                    {
                      errors.name !== "" ?
                        <small className="text-danger p-1">
                          { errors.name }
                        </small> :
                        null
                    }
                  </div>

                  <div className="col-lg-12 pt-4">
                    <div className="form-group form_style mb-0">
                      <label>Phone Number</label>
                      <input required type='text' name="phone" onChange={ handleChange } value={ state.phone } className="form-control" autoComplete="off" placeholder="Phone Number" />
                    </div>

                    {
                      errors.phone !== "" ?
                        <small className="text-danger p-1">
                          { errors.phone }
                        </small> :
                        null
                    }

                  </div>
                  <div className="col-lg-12 pt-4">
                    <div className="form-group form_style mb-0">
                      <label>Email Address</label>
                      <input required type="email" name="email" onChange={ handleChange } value={ state.email } className="form-control" autoComplete="off" placeholder="Email Address" />
                    </div>
                    {
                      errors.email !== "" ?
                        <small className="text-danger p-1">
                          { errors.email }
                        </small> :
                        null
                    }
                  </div>

                  <div className="col-lg-12 pt-4">
                    <div className="form-group form_style">
                      <label>Destination</label>
                      <select name="country_id" required className="form-control" onChange={ handleChange } >
                        <option value="" defaultValue>Select Destination</option>
                        { countries.map((country =>
                          <option key={ country.id } value={ country.id }> { country.name }</option>
                        )) }
                      </select>

                    </div>
                  </div>


                  <div className="col-lg-12">
                    <div className="form-group form_style">
                      <label>Qualification</label>
                      <input type="text" name="qualification" onChange={ handleChange } value={ state.qualification } className="form-control" autoComplete="off" />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="submit" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle btn-small" >Contact</button>
                  <button type="button" className="thm-btn bg-thm-color-six thm-color-six-shadow btn-rectangle btn-small" data-dismiss="modal">Close</button>

                </div>
              </form>
            </div>

          </div>
        </div>
      </div>


    </div>
  );
}
