import React, { useEffect } from 'react';
import Header from './components/Header';
import Professionals from './components/Professionals';
import About from './components/About';
import CounterBox from './components/CounterBox';
import WhoWeAre from './components/WhoWeAre';
import GradientCTA from '../faqs/components/GradientCTA';
export default function AboutUs() {
  useEffect(() => {
    document.title = "About Us - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);
  return (
    <div className='pt-200'>
      <Header />
      <About />
      <CounterBox />
      <WhoWeAre />
      <Professionals />
      <GradientCTA />
    </div>
  );
}
