import React, { useEffect } from 'react';
import ThreeCardsComponents from './components/ThreeCardsComponents';
import ProfessionalComponents from './components/ProfessionalComponents';
import ProviderComponent from './components/ProviderComponent';
import GradientCTA from './components/GradientCTA';
import VideosComponent from './components/VideosComponent';
import Testimonials from './components/testimonials';
import BestCoachesComponent from './components/BestCoachesComponent';
import Newsletter from './components/Newsletter';
import GradientCTA2 from './components/GradientCTA2';
import LegacySection from './components/LegacySection';
import WeOfferComponent from './components/WeOfferComponent';
import Counselling from './components/Counselling';
import CounsellingVideo from './components/CounsellingVideo';
import WhatWeKnow from './components/WhatWeKnow';
import Header from './components/Header';
export default function Main() {

  useEffect(() => {
    document.title = "International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);

  return (
    <div>
      <Header />
      <LegacySection />

      {/* <Counselling /> */ }
      <ThreeCardsComponents />
      <CounsellingVideo />
      <WhatWeKnow />

      {/* <ThreeCardsComponents />
      <ProviderComponent /> */}
      <ProfessionalComponents />
      <GradientCTA />
      <VideosComponent />
      <Testimonials />
      {/* <BestCoachesComponent /> 
      <Newsletter />
      {/* <WeOfferComponent /> */ }
      <GradientCTA2 />
    </div>
  );
}
