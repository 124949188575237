import React, { useEffect, useState } from 'react';
import { getVideos } from '../../requests';
export default function VideosComponent() {

  const [categories, setCategories] = useState([])
  const [videos, setVideos] = useState([])

  useEffect(() => {
    window.getCode()
  })

  useEffect(() => {
    getVideos().then(res => {
      setCategories(res.data.categories)
      setVideos(res.data.videos)
    }).catch(console.error)
  }, [])

  if (!videos.length > 0) {
    return ''
  }

  const topVideos = videos?.splice(0, 3)

  return (
    <div>
      <section className="section-padding isotope-filter-items pt-100" >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="title">Latest Informational Videos Testimonials & Tutorials</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="filter-btns mb-5">
                <ul>
                  {
                    categories.map(category => (
                      category.name === "Uncategory" ?
                        <li key={ category.name } className="active filter-trigger px-1" data-filter="*">
                          <a href="#">All</a>
                        </li> :
                        <li key={ category.name } className="filter-trigger px-1" data-filter={ `.${category.name}` }>
                          <a href="#">{ category.name }</a>
                        </li>

                    ))
                  }

                </ul>
              </div>
            </div>
          </div>
          <div className="row filteritems">
            {
              topVideos.map((video, index) => (
                <div key={ index.toString() } className={ `col-lg-4 col-md-6 masonry-item ${video.category.name}` }>
                  <div className="video_box">
                    <img src={ video.thumbnail } alt="img" className="image-fit" />
                    <a href={ video.url } className="popup-youtube video_btn">
                      <i className="fas fa-play" />
                    </a>
                    <div className="video_text">
                      <h6 className="text-white mb-0">{ video.title }</h6>
                      {/* <p className="mb-0 thm-color-light">By Frank L. Pierce</p> */ }
                    </div>
                  </div>
                </div>
              ))
            }


          </div>
          <div className="row filteritems">
            {
              videos?.length > 0 ? videos.map((video, index) => (
                <div key={ index.toString() } className={ `col-xl-3 col-lg-4 col-md-6 masonry-item ${video.category.name}` }>
                  <div className="video_box">
                    <img src={ video.thumbnail } alt="img" className="image-fit" />
                    <a href={ video.url } className="popup-youtube video_btn">
                      <i className="fas fa-play" />
                    </a>
                    <div className="video_text">
                      <h6 className="text-white mb-0">{ video.title }</h6>
                      {/* <p className="mb-0 thm-color-light">By Frank L. Pierce</p> */ }
                    </div>
                  </div>
                </div>
              )) :
                null
            }


          </div>
        </div>
      </section>
    </div>

  );
}
