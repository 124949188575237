import React from 'react';
import DropMessage from './DropMessage';
export default function Contact() {
  return (
    <div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6">
            <div className="section-title left-align">
              <h3 className="title">Want us to call you? <br />Fill this form</h3>
            </div>
            <div className="contact_info mb-md-80">
              <ul>
                <li>
                  <i className="icon fal fa-map-marker-alt" />
                  <div className="text">
                    <h6>Location</h6>
                    <p>1st Floor, 215-CCA, FF Block <br />DHA Phase 4 Lahore, 54000</p>
                  </div>
                </li>
                <li>
                  <i className="icon fal fa-envelope-open-text" />
                  <div className="text">
                    <h6>Email Address</h6>
                    <p>info@icc.com.pk</p>
                  </div>
                </li>
                <li>
                  <i className="icon fal fa-phone" />
                  <div className="text">
                    <h6>Contact Us</h6>
                    <p><strong>Phone: </strong> +92 (42) 37192111</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          < div className="col-xl-7 col-lg-6">

            <DropMessage />

          </div>
        </div>
      </div>
    </div>
  );
}
