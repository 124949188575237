import React, { useState, useEffect } from 'react';
import { getCountries, createContact } from '../../requests';
const initialState = {
  name: "",
  email: "",
  phone: "",
  country_id: "",
  qualification: ""
}

const validationErrors = {
  name: "",
  email: "",
  phone: "",
}
export default function DropMessage() {
  const [form, setForm] = useState(initialState)
  const [countries, setCountries] = useState([])
  const [success, setSuccess] = useState({
    isSuccess: false,
  })
  const [errors, setErrors] = useState(validationErrors)
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCountries();
      // console.log(response)
      setCountries(response.data);
    }
    fetchData().catch(console.error);;
  }, [])

  const handleFormChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    createContact({ object: form }).then(res => {
      if (res.code === 200) {
        setSuccess({
          ...success,
          isSuccess: true,
          data: res.data
        })
      } else {
        setErrors(res.data)
      }
    }).catch(console.err)
    setForm(initialState)
  }

  if (!countries) return ''
  if (countries.length === 0) {
    return <><img src='\assets\loading.jpg' alt='loading' width="50px" /></>;
  }
  return (
    <div className='container pt-4 '>
      { success.isSuccess ? <div class="alert alert-success fade in show" role="alert">
        { success.data }
      </div> : null }
      <section >
        <div className="container">

          <form className="mf_form_validate ajax_submit" onSubmit={ handleFormSubmit } method="post" encType="multipart/form-data">
            <div className="row mt-5 pt-5">
              <div className="col-lg-12">

                <div className="form-group form_style mb-0">
                  <label>Full Name</label>
                  <input required type="text" name="name" onChange={ handleFormChange } value={ form.name } className="form-control " autoComplete="off" placeholder="Full Name" />
                  {/* <div className="valid-feedback text-right">
                    Looks good!
                  </div> */}
                </div>
                {
                  errors.name !== "" ?
                    <small className="text-danger p-1">
                      { errors.name }
                    </small> :
                    null
                }
              </div>

              <div className="col-lg-12 pt-4">
                <div className="form-group form_style mb-0">
                  <label>Phone Number</label>
                  <input required type='text' name="phone" onChange={ handleFormChange } value={ form.phone } className="form-control" autoComplete="off" placeholder="Phone Number" />
                </div>

                {
                  errors.phone !== "" ?
                    <small className="text-danger p-1">
                      { errors.phone }
                    </small> :
                    null
                }

              </div>
              <div className="col-lg-12 pt-4">
                <div className="form-group form_style mb-0">
                  <label>Email Address</label>
                  <input required type="email" name="email" onChange={ handleFormChange } value={ form.email } className="form-control" autoComplete="off" placeholder="Email Address" />
                </div>
                {
                  errors.email !== "" ?
                    <small className="text-danger p-1">
                      { errors.email }
                    </small> :
                    null
                }
              </div>
              <div className="col-lg-12 pt-4">
                <div className="form-group form_style">
                  <label>Destination</label>
                  <select name="country_id" required className="form-control" onChange={ handleFormChange } >
                    <option value="" defaultValue>Select Destination</option>
                    { countries.map((country =>
                      <option key={ country.id } value={ country.id }> { country.name }</option>
                    )) }
                  </select>

                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group form_style">
                  <label>Qualification</label>
                  <input type="text" required name="qualification" onChange={ handleFormChange } value={ form.qualification } className="form-control" autoComplete="off" placeholder="I Would Like To Discuss" />
                </div>
              </div>
              <div className="col-lg-12 pt-2">
                <button type="submit" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle" >Submit <i className="fal fa-chevron-right ml-2" /></button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

  );
}
