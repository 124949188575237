import React from 'react';

export default function ThreeCardsComponents() {
  return (<div>


    <section className="section-padding pt-0 relative z-1 mb-300" style={ { backgroundImage: 'url(assets/images/bg/bg_1.png)' } } >
      <div className='counselling-header'>
        <h3>Our Counselling Process</h3>
      </div>
      <div className="container">
        <div className="testimonial_slider row arrows_none">

          <div className="slide-item col-12">
            <div className="col-lg-12 col-md-12 min-height-360" >
              <div className="features_box style_3">
                <div className="icon">
                  <img src="assets/images/newicon/goal.svg" alt="img" />
                </div>
                <h5>Nurture Your Goal's</h5>
                <p>One to One session with one of our specialized consultant's who listen and understand your goal's.</p>
                <a href="#">
                  <span>Learn More</span>
                  <i className="fal fa-chevron-right" />
                </a>
              </div>
            </div>
          </div>


          <div className="slide-item col-12">
            <div className="col-lg-12 col-md-12">
              <div className="features_box style_3 min-height-360" >
                <div className="icon">
                  <img src="assets/images/newicon/find.svg" alt="img" />
                </div>
                <h5>Find Your Program</h5>
                <p>We evaluate our students and point out the best academic program's which might suit them the most.</p>
                <a href="#">
                  <span>Learn More</span>
                  <i className="fal fa-chevron-right" />
                </a>
              </div>
            </div>
          </div>

          <div className="slide-item col-12">
            <div className="col-lg-12 col-md-12">
              <div className="features_box style_3 min-height-360" >
                <div className="icon">
                  <img src="assets/images/newicon/groom.svg" alt="img" />
                </div>
                <h5>Groom Our Students</h5>
                <p>With our personalized consultation we groom you in right direction.</p>
                <a href="#">
                  <span>Learn More</span>
                  <i className="fal fa-chevron-right" />
                </a>
              </div>
            </div>
          </div>

          <div className="slide-item col-12">
            <div className="col-lg-12 col-md-12">
              <div className="features_box style_3 min-height-360" >
                <div className="icon">
                  <img src="assets/images/newicon/orientation.svg" alt="img" />
                </div>
                <h5>Orientation Session</h5>
                <p>Once you get selected, we offer pre & post departure sessions to help you prepare for boarding and enrollment process.</p>
                <a href="#">
                  <span>Learn More</span>
                  <i className="fal fa-chevron-right" />
                </a>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div className="circle_wave" />
    </section>



  </div>
  )
}
