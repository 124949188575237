import React from "react";
import BaseLayout from "./layouts/BaseLayout";
import Main from "./modules/main/Main";
import FAQs from './modules/faqs/FAQs';
import ContactUs from './modules/contactUs/ContactUs';
import AboutUs from './modules/aboutUs/AboutUs';
import Blogs from './modules/Blogs/Blogs';
import AcademicCouching from "./modules/academicCouching/AcademicCouching";
import Pricing from "./modules/pricing/pricing";
import BlogDetails from "./modules/blogDetails/BlogDetails";
import { Routes, Route } from "react-router-dom";
import Blog from "./modules/Blogs/components/Blog";

function App() {
  return (
    <div className="App">
      <BaseLayout >
        <Routes>
          <Route path="/" element={ <Main /> } />
          <Route path="contact-us" element={ <ContactUs /> } />
          <Route path="about-us" element={ <AboutUs /> } />
          <Route path="faqs" element={ <FAQs /> } />
          <Route path="blogs/:title" element={ <BlogDetails /> } />
          <Route path="blogs" element={ <Blogs /> } />
          <Route path="pricing" element={ <Pricing /> } />
          <Route path="academic-coaching" element={ <AcademicCouching /> } />
        </Routes>
      </BaseLayout>

    </div>
  );
}

export default App;
