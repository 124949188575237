import React from 'react';

export default function WhoWeAre() {
  return (
    <div>
      <section className="section section-bg about_bg about style_2" style={ { backgroundImage: 'url(assets/images/bg/bg_1.png)' } }>
        <div className="container">
          <div className="row justify-content-between flex-row-reverse">
            <div className="col-lg-6">
              <div className="image_boxes style_2 relative z-1 h-100">
                <img src="assets/images/about/small_2.jpg" className="small_img" alt="img" />
                <div className="video_warp style_2 relative z-1 big_img">
                  <img src="assets/images/about/about_img_2.jpg" alt="img" />
                  <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="popup-youtube transform-center justify-content-center d-flex">
                    <img src="assets/images/icons/youtube.png" alt="icon" />
                  </a>
                </div>
                {/* elements */ }
                <img src="assets/images/elements/element_22.png" className="element_1" alt="Element" />
                <img src="assets/images/elements/element_23.png" className="element_2 rotate_elem" alt="Element" />
                <img src="assets/images/elements/element_24.png" className="element_3 rotate_elem" alt="Element" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-md-80">
              <div className="section-title left-align">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  Who We Are
                </p>
                <h3 className="title">Upgrade Your Skills Build Your Life</h3>
                <p className="mb-0">Quis autem vel eum iure reprehenderit qui in ea voluptate esse quam nihil molestiae conseq uaturvel illum qui dolorem eum fugiat quo voluptas nulla pariatur </p>
              </div>
              <ul className="about_list style_2 mb-xl-30">
                <li>
                  Business Consulting
                </li>
                <li>
                  Career Development
                </li>
                <li>
                  Financial Solutions
                </li>
              </ul>
              <a href="#" className="thm-btn bg-thm-color-three thm-color-three
              -shadow btn-rectangle">
                Learn More
                <i className="fal fa-chevron-right ml-2" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
