const BaseFooter = () => (
    <footer className="footer mt-5">
        <div className="container">
            <div className="footer_top section-padding">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="ft_widget ft_about">
                            <a href="/" className="logo">
                                <img src="/assets/images/newimages/logo.png" alt="logo" className="image-fit height-60" />
                            </a>
                            <ul className="info">
                                <li>
                                    <a href="#">1st Floor, 215-CCA, FF Block <br />DHA Phase 4 Lahore, 54000</a>
                                </li>
                                <li>
                                    <a href="tel:+924237192111">+92 (42) 37192111</a>
                                </li>
                                <li>
                                    <a href="mailto:info@icc.com.pk">info@icc.com.pk</a>
                                </li>
                            </ul>
                            <ul className="social font-size-25">
                                <li>
                                    <a href="https://www.facebook.com/icccounselling" target="_blank">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/icc.consulting" target="_blank">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-youtube" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ft_widget ft_menu">
                            <h6 className="ft-title">Top universities</h6>
                            <ul>
                                <li>
                                    <a href="https://london.ac.uk/" target="_blank">University of London</a>
                                </li>
                                <li>
                                    <a href="https://www.sydney.edu.au/" target="_blank"> The University of Sydney</a>
                                </li>
                                <li>
                                    <a href="https://www.nyu.edu/" target="_blank"> New York University</a>
                                </li>
                                <li>
                                    <a href="https://www.utoronto.ca/" target="_blank">University of Toronto</a>
                                </li>
                                <li>
                                    <a href="https://www.um.edu.my/" target="_blank">University of Malaya</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ft_widget ft_menu">
                            <h6 className="ft-title">Student visa requirements</h6>
                            <ul>
                                <li>
                                    <a href="#" target="_blank">UK/Europe student visa requirements</a>
                                </li>
                                <li>
                                    <a href="#">USA student visa requirements</a>
                                </li>
                                <li>
                                    <a href="#">Canada student visa requirements</a>
                                </li>
                                <li>
                                    <a href="#">Australia student visa requirements</a>
                                </li>
                                <li>
                                    <a href="#">Malaysia student visa requirements</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ft_widget ft_menu">
                            <h6 className="ft-title">Connect with ICC</h6>
                            <ul>
                                <li>
                                    <a href="#">Request an Appointment</a>
                                </li>
                                <li>
                                    <a href="#">About ICC</a>
                                </li>
                                <li>
                                    <a href="#">Instution answers</a>
                                </li>
                                <li>
                                    <a href="#">F.A.Q</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div className="phone-parent">
                <a href="tel:+924237192111"><div className="phone-footer">
                    <img src="/assets/images/phone-ringing.gif" alt="call us" />
                </div></a>
            </div>
            <div className="footer_bottom">
                <p className="mb-0">
                    © 2022 <a href="#">icc.com.pk</a>.All Rights Reserved, Powered By <a href="http://greyneon.com" target="_blank">Greyneon.com</a>
                </p>
            </div>
        </div>
    </footer>

)

export default BaseFooter;