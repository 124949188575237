import React from 'react';

export default function FAQ() {
  return (
    <div>
      <div className="section-padding faqs_elements">
        <div className="container relative z-1">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-11">
              <ul className="nav nav-tabs style_3">
                <li className="nav-item">
                  <a href="#general" className="nav-link active" data-toggle="tab">General</a>
                </li>
                <li className="nav-item">
                  <a href="#allcoach" className="nav-link" data-toggle="tab">All Coach</a>
                </li>
                <li className="nav-item">
                  <a href="#advisors" className="nav-link" data-toggle="tab">Advisors</a>
                </li>
                <li className="nav-item">
                  <a href="#tutorials" className="nav-link" data-toggle="tab">Tutorials</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="general">
                  <div className="accordion accordion-style style_2 mb-xl-30" id="generalaccordion">
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#GeneralItemOne" aria-expanded="true" aria-controls="GeneralItemOne">
                          We Teach You How To Coach Yourself.
                        </button>
                      </div>
                      <div id="GeneralItemOne" className="collapse show" aria-labelledby="GeneralItemOne" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemTwo" aria-expanded="false" aria-controls="GeneralItemTwo">
                          We Teach You How To Feel Better.
                        </button>
                      </div>
                      <div id="GeneralItemTwo" className="collapse" aria-labelledby="GeneralItemTwo" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemThree" aria-expanded="false" aria-controls="GeneralItemThree">
                          We Teach You How To Take Massive Action.
                        </button>
                      </div>
                      <div id="GeneralItemThree" className="collapse" aria-labelledby="GeneralItemThree" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemFour" aria-expanded="false" aria-controls="GeneralItemFour">
                          Be Less Frustrated By Learning Relationship Tools
                        </button>
                      </div>
                      <div id="GeneralItemFour" className="collapse" aria-labelledby="GeneralItemFour" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemFive" aria-expanded="false" aria-controls="GeneralItemFive">
                          Motivate Yourself To Get More Done In Less Time
                        </button>
                      </div>
                      <div id="GeneralItemFive" className="collapse" aria-labelledby="GeneralItemFive" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemSix" aria-expanded="false" aria-controls="GeneralItemSix">
                          Ease Anxiety, Stress, Worry, And Boredom
                        </button>
                      </div>
                      <div id="GeneralItemSix" className="collapse" aria-labelledby="GeneralItemSix" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#GeneralItemSeven" aria-expanded="false" aria-controls="GeneralItemSeven">
                          Process Emotion And Create More Energy
                        </button>
                      </div>
                      <div id="GeneralItemSeven" className="collapse" aria-labelledby="GeneralItemSeven" data-parent="#generalaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="allcoach">
                  <div className="accordion accordion-style style_2 mb-xl-30" id="allcoachaccordion">
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#AllCoachItemOne" aria-expanded="true" aria-controls="AllCoachItemOne">
                          We Teach You How To Coach Yourself.
                        </button>
                      </div>
                      <div id="AllCoachItemOne" className="collapse show" aria-labelledby="AllCoachItemOne" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemTwo" aria-expanded="false" aria-controls="AllCoachItemTwo">
                          We Teach You How To Feel Better.
                        </button>
                      </div>
                      <div id="AllCoachItemTwo" className="collapse" aria-labelledby="AllCoachItemTwo" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemThree" aria-expanded="false" aria-controls="AllCoachItemThree">
                          We Teach You How To Take Massive Action.
                        </button>
                      </div>
                      <div id="AllCoachItemThree" className="collapse" aria-labelledby="AllCoachItemThree" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemFour" aria-expanded="false" aria-controls="AllCoachItemFour">
                          Be Less Frustrated By Learning Relationship Tools
                        </button>
                      </div>
                      <div id="AllCoachItemFour" className="collapse" aria-labelledby="AllCoachItemFour" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemFive" aria-expanded="false" aria-controls="AllCoachItemFive">
                          Motivate Yourself To Get More Done In Less Time
                        </button>
                      </div>
                      <div id="AllCoachItemFive" className="collapse" aria-labelledby="AllCoachItemFive" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemSix" aria-expanded="false" aria-controls="AllCoachItemSix">
                          Ease Anxiety, Stress, Worry, And Boredom
                        </button>
                      </div>
                      <div id="AllCoachItemSix" className="collapse" aria-labelledby="AllCoachItemSix" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AllCoachItemSeven" aria-expanded="false" aria-controls="AllCoachItemSeven">
                          Process Emotion And Create More Energy
                        </button>
                      </div>
                      <div id="AllCoachItemSeven" className="collapse" aria-labelledby="AllCoachItemSeven" data-parent="#allcoachaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="advisors">
                  <div className="accordion accordion-style style_2 mb-xl-30" id="advisorsaccordion">
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#AdvisorsItemOne" aria-expanded="true" aria-controls="AdvisorsItemOne">
                          We Teach You How To Coach Yourself.
                        </button>
                      </div>
                      <div id="AdvisorsItemOne" className="collapse show" aria-labelledby="AdvisorsItemOne" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemTwo" aria-expanded="false" aria-controls="AdvisorsItemTwo">
                          We Teach You How To Feel Better.
                        </button>
                      </div>
                      <div id="AdvisorsItemTwo" className="collapse" aria-labelledby="AdvisorsItemTwo" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemThree" aria-expanded="false" aria-controls="AdvisorsItemThree">
                          We Teach You How To Take Massive Action.
                        </button>
                      </div>
                      <div id="AdvisorsItemThree" className="collapse" aria-labelledby="AdvisorsItemThree" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemFour" aria-expanded="false" aria-controls="AdvisorsItemFour">
                          Be Less Frustrated By Learning Relationship Tools
                        </button>
                      </div>
                      <div id="AdvisorsItemFour" className="collapse" aria-labelledby="AdvisorsItemFour" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemFive" aria-expanded="false" aria-controls="AdvisorsItemFive">
                          Motivate Yourself To Get More Done In Less Time
                        </button>
                      </div>
                      <div id="AdvisorsItemFive" className="collapse" aria-labelledby="AdvisorsItemFive" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemSix" aria-expanded="false" aria-controls="AdvisorsItemSix">
                          Ease Anxiety, Stress, Worry, And Boredom
                        </button>
                      </div>
                      <div id="AdvisorsItemSix" className="collapse" aria-labelledby="AdvisorsItemSix" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#AdvisorsItemSeven" aria-expanded="false" aria-controls="AdvisorsItemSeven">
                          Process Emotion And Create More Energy
                        </button>
                      </div>
                      <div id="AdvisorsItemSeven" className="collapse" aria-labelledby="AdvisorsItemSeven" data-parent="#advisorsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tutorials">
                  <div className="accordion accordion-style style_2 mb-xl-30" id="tutorialsaccordion">
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#TutorialsItemOne" aria-expanded="true" aria-controls="TutorialsItemOne">
                          We Teach You How To Coach Yourself.
                        </button>
                      </div>
                      <div id="TutorialsItemOne" className="collapse show" aria-labelledby="TutorialsItemOne" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemTwo" aria-expanded="false" aria-controls="TutorialsItemTwo">
                          We Teach You How To Feel Better.
                        </button>
                      </div>
                      <div id="TutorialsItemTwo" className="collapse" aria-labelledby="TutorialsItemTwo" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemThree" aria-expanded="false" aria-controls="TutorialsItemThree">
                          We Teach You How To Take Massive Action.
                        </button>
                      </div>
                      <div id="TutorialsItemThree" className="collapse" aria-labelledby="TutorialsItemThree" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemFour" aria-expanded="false" aria-controls="TutorialsItemFour">
                          Be Less Frustrated By Learning Relationship Tools
                        </button>
                      </div>
                      <div id="TutorialsItemFour" className="collapse" aria-labelledby="TutorialsItemFour" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemFive" aria-expanded="false" aria-controls="TutorialsItemFive">
                          Motivate Yourself To Get More Done In Less Time
                        </button>
                      </div>
                      <div id="TutorialsItemFive" className="collapse" aria-labelledby="TutorialsItemFive" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemSix" aria-expanded="false" aria-controls="TutorialsItemSix">
                          Ease Anxiety, Stress, Worry, And Boredom
                        </button>
                      </div>
                      <div id="TutorialsItemSix" className="collapse" aria-labelledby="TutorialsItemSix" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#TutorialsItemSeven" aria-expanded="false" aria-controls="TutorialsItemSeven">
                          Process Emotion And Create More Energy
                        </button>
                      </div>
                      <div id="TutorialsItemSeven" className="collapse" aria-labelledby="TutorialsItemSeven" data-parent="#tutorialsaccordion">
                        <div className="card-body">
                          Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam tatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/elements/element_10.png" alt="element" className="element_1 zoom-fade" />
          <img src="assets/images/elements/element_35.png" alt="element" className="element_2 zoom-fade" />
          <img src="assets/images/elements/element_36.png" alt="element" className="element_3 rotate_elem" />
          <img src="assets/images/elements/element_37.png" alt="element" className="element_4 rotate_elem" />
          <img src="assets/images/elements/element_38.png" alt="element" className="element_5 rotate_elem" />
        </div>
      </div>
    </div>

  );
}
