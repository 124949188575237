import React, { useEffect } from 'react';
import Header from './components/Header';
import PricingDetails from './components/PricingDetails';
import PricingSlider from './components/PricingSlider/PricingSlider';
export default function Pricing() {
  useEffect(() => {
    document.title = "pricing - International counselling center";
    window.getCode();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);
  return <div className='pt-200 pb-100'>
    <Header />
    <PricingDetails />
    <PricingSlider />
  </div>;
}
