import React from 'react';

export default function CounterBox() {
  return (
    <div>
      <div className="section-padding pt-0">
        <div className="container">
          <div className="row">
            {/* Box */ }
            <div className="col-lg-3 col-md-6">
              <div className="counter_box">
                <div className="icon">
                  <img src="assets/images/icons/icon_6.png" alt="icon" className="image-fit" />
                </div>
                <div className="text">
                  <div className="counter">
                    <b data-from={ 0 } data-to={ 369 }>0</b>
                  </div>
                  <p className="mb-0">Popular Coach</p>
                </div>
              </div>
            </div>
            {/* Box */ }
            {/* Box */ }
            <div className="col-lg-3 col-md-6">
              <div className="counter_box">
                <div className="icon">
                  <img src="assets/images/icons/icon_7.png" alt="icon" className="image-fit" />
                </div>
                <div className="text">
                  <div className="counter">
                    <b data-from={ 0 } data-to={ 264 }>0</b>
                  </div>
                  <p className="mb-0">Our Advisors</p>
                </div>
              </div>
            </div>
            {/* Box */ }
            {/* Box */ }
            <div className="col-lg-3 col-md-6">
              <div className="counter_box">
                <div className="icon">
                  <img src="assets/images/icons/icon_8.png" alt="icon" className="image-fit" />
                </div>
                <div className="text">
                  <div className="counter">
                    <b data-from={ 0 } data-to={ 259 }>0</b>
                  </div>
                  <p className="mb-0">Video Tutorials</p>
                </div>
              </div>
            </div>
            {/* Box */ }
            {/* Box */ }
            <div className="col-lg-3 col-md-6">
              <div className="counter_box">
                <div className="icon">
                  <img src="assets/images/icons/icon_9.png" alt="icon" className="image-fit" />
                </div>
                <div className="text">
                  <div className="counter">
                    <b data-from={ 0 } data-to={ 568 }>0</b>
                  </div>
                  <p className="mb-0">Student Reviews</p>
                </div>
              </div>
            </div>
            {/* Box */ }
          </div>
        </div>
      </div>
    </div>

  );
}
