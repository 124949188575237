import React from 'react';

export default function GradientCTA2() {
  return (
    <div className='mb-5'>
      <div className="bg-thm-color-two plane_box relative z-1">
        <div className="container relative z-1">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-11 relative z-1">
              <img src="assets/images/elements/element_6.png" className="element_1 rotate_elem" alt="Element" />
              <h3 className="thm-color-white mb-2">International Counselling Centre</h3>
              <h4 className="thm-color-white">Achieving High, Our Legacy</h4>
            </div>
          </div>
          <img src="assets/images/elements/element_7.png" className="element_2 zoom-fade" alt="Element" />
        </div>
      </div>
    </div>

  );
}
