import React from 'react';
const dummyTitle = "We Care About Your Life For Better Future"
const dummySubtitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel"
const dummyImg = "assets/images/about/about.jpg"
export default function CouchingComponent({ flag = false,
  icon, title = dummyTitle,
  subtitle = dummySubtitle,
  img = dummyImg,
  arrow = true }) {
  return <div>
    <section className="section about_inner py-30
    ">
      <div className="container">
        {
          flag ?
            (
              <div className="row align-items-md-center">

                <div className="col-md-6">
                  <div className="image_box shadow_1 mb-md-80 img-couching w-75 m-auto " >
                    <img src={ img } alt="img" className="image-fit" />
                  </div>
                </div>
                <div className="col-md-6 mt-sm-50">
                  <div className="section-title left-align">
                    <p className="subtitle">
                      <img src={ icon } alt="icon" />
                    </p>
                    <h3 className="title">{ title }</h3>
                    <p>{ subtitle }</p>
                  </div>

                  <a href="#" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle">
                    Learn More
                    <i className="fal fa-chevron-right ml-2" />
                  </a>
                </div>
                { arrow ? <div className='w-50 m-auto mt-2 d-none d-md-block'>
                  <img src="assets/images/couching/arrow2.png" className='w-100' alt="arrow" />
                </div> : null }
              </div>
            ) :
            (
              <div className="row flex-column-reverse flex-md-row align-items-center ">

                <div className="col-md-6 mt-sm-50">
                  <div className="section-title left-align">
                    <p className="subtitle">
                      <img src={ icon } alt="icon" />
                    </p>
                    <h3 className="title">{ title }</h3>
                    <p>{ subtitle }</p>
                  </div>

                  <a href="#" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle">
                    Learn More
                    <i className="fal fa-chevron-right ml-2" />
                  </a>
                </div>
                <div className="col-md-6">
                  <div className="image_box shadow_1 mb-md-80 img-couching w-75  m-auto " >
                    <img src={ img } alt="img" className="image-fit" />
                  </div>
                </div>
                <div className='w-50 m-auto mt-2 d-none d-md-block '>
                  <img src="assets/images/couching/arrow1.png" className='w-100' alt="arrow" />
                </div>
              </div>
            )
        }

      </div>
    </section>
  </div>;
}
