import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import EmployeeModal from '../common/EmployeeModal';
const BaseHeader = () => {
    const [state, setState] = useState(false)
    const handleState = () => {
        setState(prevState => !prevState)
    }
    const pages = [
        {
            title: "Home",
            path: "/"
        },
        {
            title: "Blogs",
            path: "/blogs"
        },
        {
            title: "Coaching",
            path: "/academic-coaching"
        },
        {
            title: "FAQ",
            path: "/faqs"
        },
        {
            title: "About Us",
            path: "/about-us"
        },
        {
            title: "Contact Us",
            path: "/contact-us"
        }
    ]
    return (
        <div>
            <EmployeeModal />
            <aside className={ `aside_bar aside_bar_left aside_mobile ${state === true ? "open" : ""} ` }>
                <a href="/" className="logo">
                    <img src="/assets/images/logo-svg.png" style={ {
                        width: "100px",
                        marginTop: "30px"
                    } } alt="logo" />
                </a>
                <nav>
                    <ul className="main-menu header" style={ { marginTop: "20px" } } >
                        { pages.map((page, i) => (
                            <li className="menu-item" key={ i.toString() }>
                                <NavLink to={ `${page.path.toLowerCase()}` } key={ `${page.toString()}` } style={ { textDecoration: "none" } } className={ ({ isActive }) => isActive ? "activeLink" : "inActiveLink" } >
                                    { page.title }
                                    <div
                                        className="hoverClass">

                                    </div>
                                </NavLink>
                            </li>
                        )) }
                    </ul>
                </nav>
            </aside>
            <div className="aside-overlay trigger-left" onClick={ handleState } />

            <header className="header header-absolute can-sticky">
                <div className="container">
                    <div className="nav_warp">
                        <nav>
                            <div className="logo" >
                                <a href="/">
                                    <img src="/assets/images/logo-svg.png" alt="logo" className="image-fit" />
                                </a>
                            </div>
                            <ul className="main-menu">
                                { pages.map((page) => (
                                    <li className="menu-item" key={ page.title }>
                                        <NavLink to={ `${page.path.toLowerCase()}` } key={ `${page.toString()}` } style={ { textDecoration: "none" } } className={ ({ isActive }) => isActive ? "active-link" : "inActiveLink" } >
                                            { page.title }
                                            <div
                                                className="hover-class">

                                            </div>
                                        </NavLink>
                                    </li>
                                )) }
                            </ul>
                        </nav>
                        <div className="head_actions">
                            <a href="contact.html" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle" data-toggle="modal" data-target="#exampleModal">Enroll Now <i className="fal fa-chevron-right ml-2"></i></a>

                            <button type="button" className="head_trigger mobile_trigger" onClick={ handleState }>
                                <span />
                                <span />
                                <span />
                            </button>
                        </div>
                    </div>
                </div>

            </header>

        </div>
    )
}

export default BaseHeader;