import React, { useState } from 'react';
import classes from "./PricingSlider.module.css"
export default function PricingSlider() {
  const [value, setValue] = useState(0)
  const price = 10
  const handleSliderChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      <div className={ classes.heading }>
        <h3>Calculate Consultancy Hours</h3>
      </div>
      <div className={ classes.pricing } >
        <ul class="list-group list-group-horizontal w-75 m-auto">
          <li class="list-group-item w-75">
            {/* <div className='col-2 '>0</div> */ }
            <div >
              <input type="range" min={ 0 } max={ 24 } step={ 1 } onChange={ handleSliderChange } value={ value } />
              {/* <div className='col-2 '>0</div> */ }

            </div>
            <div className={ classes.pricing_slider_value } />
          </li>
          <li class="list-group-item w-25"><div className={ classes.pricing_item_price }>
            <span className={ classes.pricing_item_price_amount }>
              { value }
            </span>
            hr
          </div></li>
        </ul>
        <ul class="list-group list-group-horizontal w-75 m-auto pt-2">
          <li class="list-group-item w-33">
            <span>Price/hr<h4>${ price }</h4></span>
          </li>
          <li class="list-group-item w-33" >
            <span>Total Hours<h4>{ value }</h4></span>
          </li>
          <li class="list-group-item w-33" >
            <span>Total Price<h4>${ value * price }</h4></span>
          </li>
        </ul>
      </div >
    </>)
}
