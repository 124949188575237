import React, { useEffect, useState } from 'react';
import { getBlogs } from '../../requests';
export default function Blog() {
    const [blogs, setBlogs] = useState()
    useEffect(() => {
        getBlogs()
            .then((res) => {
                console.log(res)
                setBlogs(res)
            })
            .catch(console.error)
    }, [])

    if (blogs ? blogs.length > 0 : false) {
        return <div className="section-padding">
            <div className="container"> <h3 className='section-padding text-center'>
                No Blogs Found
            </h3>
            </div>
        </div>
    }

    const showDescription = (bodyStr) => {
        return bodyStr.replace(/(<([^>]+)>)/gi, "");
    }
    return (
        <div>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        {/* post */ }
                        {
                            blogs?.data.map(blog => (
                                <div className="col-xl-4 col-md-6">
                                    <article className="post style_2">
                                        <div className="post_img">
                                            <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="img" className="image-fit" />
                                        </div>
                                        <div className="post_caption">
                                            <ul className="post_meta">
                                                <li>
                                                    <i className="fal fa-calendar-alt" />
                                                    25 Mar 2021
                                                </li>
                                                <li>
                                                    <i className="fal fa-comment-lines" />
                                                    Comments (05)
                                                </li>
                                            </ul>
                                            <h4 className="post_title">
                                                <a href={ `blogs/${blog.slug}` }>{ blog.title }</a>
                                            </h4>
                                            <p className="post_text">
                                                { showDescription(blog.body) }
                                            </p>
                                            <a href={ `blogs/${blog.slug}` } className="thm-btn bg-thm-color-white thm-color-one btn-rectangle">Read More <i className="fal fa-chevron-right ml-2" /></a>
                                        </div>
                                    </article>
                                </div>
                            ))
                        }

                        {/* post */ }
                        {/* post */ }


                    </div>
                    {/* Pagination */ }
                    {/* <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a href="#" className="page-link">
                  <i className="fal fa-chevron-left" />
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">01</a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">02</a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">03</a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  <i className="fal fa-chevron-right" />
                </a>
              </li>
            </ul>
          </nav> */}
                    {/* Pagination */ }
                </div>
            </div>
        </div>

    );
}
