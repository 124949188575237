import React from 'react';
import { Link } from 'react-router-dom';

export default function ProfessionalComponents() {
  return (
    <div>
      <section className="section-padding bg-thm-color-two-gradient z-1 team_main_wrap pb-extra">
        <img src="assets/images/elements/element_1.png" className="element_1" alt="Element" />
        <img src="assets/images/elements/element_2.png" className="element_2" alt="Element" />
        <div className="container">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="section-title">
                <h3 className="title">Meet Our Consultants</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg col-md-6">
              <div className="team_block">
                <div className="team_img">
                  <img src="assets/images/team/adeel.jpeg" alt="img" className="image-fit" />
                  <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                    <i className="fal fa-plus" />
                  </a>
                </div>
                <h6 className="mb-1"><a href="#">Adeel Zaidi</a></h6>
                <p className="thm-color-two mb-0 font-weight-bold">CEO</p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="team_block">
                <div className="team_img">
                  <img src="assets/images/team/ayaz.jpeg" alt="img" className="image-fit" />
                  <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                    <i className="fal fa-plus" />
                  </a>
                </div>
                <h6 className="mb-1"><a href="#">Ayaz Malik</a></h6>
                <p className="thm-color-two mb-0 font-weight-bold">COO</p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="team_block">
                <div className="team_img">
                  <img src="assets/images/team/sohaib.jpeg" alt="img" className="image-fit" />
                  <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                    <i className="fal fa-plus" />
                  </a>
                </div>
                <h6 className="mb-1"><a href="#">Sohaib Ul Hasnain</a></h6>
                <p className="thm-color-two mb-0 font-weight-bold">Managing Director</p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="team_block">
                <div className="team_img">
                  <img src="assets/images/team/iqra.jpeg" alt="img" className="image-fit" />
                  <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                    <i className="fal fa-plus" />
                  </a>
                </div>
                <h6 className="mb-1"><a href="#">Iqra Iqtidar</a></h6>
                <p className="thm-color-two mb-0 font-weight-bold">Director Business
                  & Marketing</p>
              </div>
            </div>

            {/* <div className="col-lg col-md-6">
              <div className="team_block">
                <div className="team_img">
                  <img src="assets/images/team/5.jpg" alt="img" className="image-fit" />
                  <a href="#" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                    <i className="fal fa-plus" />
                  </a>
                </div>
                <h6 className="mb-1"><a href="#">Donald T.Lewis</a></h6>
                <p className="thm-color-two mb-0 font-weight-bold">Coach Teacher</p>
              </div>
            </div> */}
          </div>
          <div className='text-center mt-5' >
            <Link to={ "pricing" }>
              <button type="submit" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rectangle">Consult now <i className="fal fa-chevron-right ml-2"></i></button>
            </Link>
          </div>
        </div>
      </section>
    </div>

  );
}
