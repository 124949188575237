import React from 'react';

export default function CounsellingVideo() {



  return (<div>
    {/* Video Start */ }
    <div className="section pt-0 video_box_elements mt-negative">
      <div className="container relative z-1">
        <div className="row">
          <div className="col-12">
            <div className="video_warp style_2 relative z-1 wow fadeInDown">
              <img src="assets/images/youtube.jpg" alt="img" className="image-fit" />
              <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="popup-youtube transform-center justify-content-center d-flex">
                <img src="assets/images/icons/youtube.png" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img src="assets/images/elements/element_25.png" alt="element" className="element_1 rotate_elem" />
        <img src="assets/images/elements/element_30.png" alt="element" className="element_2 rotate_elem" />
        <img src="assets/images/elements/element_27.png" alt="element" className="element_3 rotate_elem" />
        <div className="arrows to_up one slideTop">
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
        </div>
        <div className="arrows to_up two slideTop">
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
        </div>
      </div>
    </div>
    {/* Video End */ }


  </div>);
}
